<template>
    <div class="my-order">
        <van-tabs v-model="active" :line-width="60" animated swipeable sticky title-inactive-color="#999" title-active-color="#353334" color="#EC2627">
            <van-tab title="全部">             
                <div class="my-con" >
                    <!-- 兑换码 -->
                    <form action="/">
                          <van-search 
                            v-model.trim="convCode" 
                            show-action placeholder="请输入兑换码">
                                <template #action>
                                    <div @touchstart="onSearch">兑换</div>
                                </template>
                            </van-search>
                    </form>
                    <!-- 列表 -->
                    <div class="goods-list" v-if="data.length">
                        <div class="goods-item" v-for="item in data" :key="item.id">
                            <div class="item-top" @click="hrefUrl(item)">
                                <div class="item-img">
                                    <van-tag type="success" class="tag" v-if="item.order_code_status == 0">{{item.order_code_status_text}}</van-tag>
                                    <van-tag type="success" class="tag" v-if="item.order_code_status == 1"> {{item.order_code_status_text}}</van-tag>
                                    <van-tag type="danger" class="tag" v-if="item.order_code_status == 2"> {{item.order_code_status_text}}</van-tag>
                                    <van-tag type="success" class="tag" v-if="item.order_code_status == 3"> {{item.order_code_status_text}}</van-tag>
                                    <van-tag type="success" class="tag" v-if="item.order_code_status == 4"> {{item.order_code_status_text}}</van-tag>
                                    <van-tag type="default" class="tag" v-if="item.order_code_status == 5"> {{item.order_code_status_text}}</van-tag>
                                    <van-image fit="fill" :src="item.get_goods.thumb_pic || 'https://khome2.tuzuu.com/klfront/newlogo.png'" width="100%" height="100%"/>
                                </div>
                                <div class="item-info">
                                    <div>
                                        <div class="item-info-title van-ellipsis">{{item.title}}</div>
                                        <div class="item-info-con van-multi-ellipsis--l2"> {{item.get_goods.check_items}}</div>
                                    </div>
                                    <div>
                                        <div class="info">
                                            <div class="item-info-money">
                                                <span style="font-size: 9px">¥</span><span style="font-size: 17px">{{ item.order_price.split(".")[0] }}</span>.<span>{{ item.order_price.split(".")[1] }}</span>
                                            </div>
                                            <div class="item-info-time">{{item.created_at}} </div>
                                        </div>
                                        <div class="item-bottom-button">      
                                            <van-button round type="danger" size="small" v-if="item.order_code_status == 0" >去预约</van-button>
                                            <van-button round type="primary" size="small" v-if="item.order_code_status == 2" @click.self.stop="createOrder(item.sn)">去支付</van-button>
                                            <van-button round type="info" size="small" v-if="item.order_code_status == 1 || item.order_code_status == 3 || item.order_code_status ==4" @click.self.stop="openEwm(item)">查看详情</van-button>
											 <van-button round  size="small" v-if="(item.order_code_status == 1 || item.order_code_status == 3 || item.order_code_status ==4) && item.comment_state == 1" @click.self.stop="goMsg(item)">去评价</van-button>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <van-empty description="暂无数据" v-else/>
                </div>        
            </van-tab>
            <van-tab title="待支付">
                <div class="my-con">
                    <!-- 兑换码 -->
                    <form action="/">
                          <van-search 
                            v-model="convCode" 
                            show-action placeholder="请输入兑换码">
                                <template #action>
                                    <div @touchstart="onSearch">兑换</div>
                                </template>
                            </van-search>
                    </form>
                    <!-- 列表 -->
                    <div class="goods-list" v-if="didPay.length">
                        <div class="goods-item" v-for="item in didPay" :key="item.id">
                            <div class="item-top" @click="hrefUrl(item)">
                                <div class="item-img">
                                    <van-tag type="danger" class="tag" v-if="item.order_code_status == 2"> {{item.order_code_status_text}}</van-tag>
                                    <van-image fit="fill" :src="item.get_goods.thumb_pic || 'https://khome2.tuzuu.com/klfront/newlogo.png'" width="100%" height="100%"/>
                                </div>
                                <div class="item-info">
                                    <div>
                                        <div class="item-info-title van-ellipsis">{{item.title}}</div>
                                        <div class="item-info-con van-multi-ellipsis--l2"> {{item.get_goods.check_items}}</div>
                                    </div>
                                    <div>
                                        <div class="info">
                                            <div class="item-info-money">
                                                <span style="font-size: 9px">¥</span><span style="font-size: 17px">{{ item.order_price.split(".")[0] }}</span>.<span>{{ item.order_price.split(".")[1] }}</span>
                                            </div>
                                            <div class="item-info-time"> {{item.created_at}} </div>
                                        </div>
                                        <div class="item-bottom-button">
                                            <van-button round type="primary" size="small" v-if="item.order_code_status == 2" @click.self.stop="createOrder(item.sn)">去支付</van-button>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     <van-empty description="暂无数据" v-else/>
                </div>
            </van-tab>
            <van-tab title="已支付">
                <div class="my-con">
                    <!-- 兑换码 -->
                    <form action="/">
                          <van-search 
                            v-model="convCode" 
                            show-action placeholder="请输入兑换码">
                                <template #action>
                                    <div @touchstart="onSearch">兑换</div>
                                </template>
                            </van-search>
                    </form>
                    <!-- 列表 -->
                    <div class="goods-list" v-if="havePay.length">
                        <div class="goods-item" v-for="item in havePay" :key="item.id">
                            <div class="item-top" @click="hrefUrl(item)">
                                <div class="item-img">
                                    <van-tag type="success" class="tag"> {{item.order_code_status_text}}</van-tag>
                                    <van-image fit="fill" :src="item.get_goods.thumb_pic || 'https://khome2.tuzuu.com/klfront/newlogo.png'" width="100%" height="100%"/>
                                </div>
                                <div class="item-info">
                                    <div>
                                        <div class="item-info-title van-ellipsis">{{item.title}}</div>
                                        <div class="item-info-con van-multi-ellipsis--l2"> {{item.get_goods.check_items}}</div>
                                    </div>
                                    <div>
                                        <div class="info">
                                            <div class="item-info-money">
                                                <span style="font-size: 9px">¥</span><span style="font-size: 17px">{{ item.order_price.split(".")[0] }}</span>.<span>{{ item.order_price.split(".")[1] }}</span>
                                            </div>
                                            <div class="item-info-time">{{item.created_at}} </div>
                                        </div>
                                        <div class="item-bottom-button">
                                            <van-button round type="danger" size="small" v-if="item.order_code_status == 0" >去预约</van-button>
											  <van-button round type="info" size="small" v-else @click.self.stop="openEwm(item)">查看详情</van-button>
                                            <van-button round  size="small" v-if="item.comment_state == 1 " @click.self.stop="goMsg(item)">去评价</van-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <van-empty description="暂无数据" v-else/>
                </div>
            </van-tab>
			<!-- 已支付并且有评论 就是已完成 -->
            <van-tab title="已完成">
                <div class="my-con">
                    <!-- 兑换码 -->
                    <form action="/">
                          <van-search 
                            v-model="convCode" 
                            show-action placeholder="请输入兑换码">
                                <template #action>
                                    <div @touchstart="onSearch">兑换</div>
                                </template>
                            </van-search>
                    </form>
                    <!-- 列表 -->
                    <div class="goods-list" v-if="okPay.length">
                        <div class="goods-item" v-for="item in okPay" :key="item.id">
                            <div class="item-top" @click="hrefUrl(item)">
                                <div class="item-img">
                                    <van-tag type="success" class="tag">{{item.order_code_status_text}}</van-tag>
                                    <van-image fit="fill" :src="item.get_goods.thumb_pic || 'https://khome2.tuzuu.com/klfront/newlogo.png'" width="100%" height="100%"/>
                                </div>
                                <div class="item-info">
                                    <div>
                                        <div class="item-info-title van-ellipsis">{{item.title}}</div>
                                        <div class="item-info-con van-multi-ellipsis--l2"> {{item.get_goods.check_items}}</div>
                                    </div>
                                    <div>
                                        <div class="info">
                                            <div class="item-info-money">
                                                <span style="font-size: 9px">¥</span><span style="font-size: 17px">{{ item.order_price.split(".")[0] }}</span>.<span>{{ item.order_price.split(".")[1] }}</span>
                                            </div>
                                            <div class="item-info-time">{{item.created_at}} </div>
                                        </div>
                                        <div class="item-bottom-button">
                                            <van-button round type="info" size="small"  @click.self.stop="openEwm(item)">查看详情</van-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <van-empty description="暂无数据" v-else/>
                </div>
            </van-tab>
            
			<van-tab title="已取消">
                <div class="my-con">
                    <!-- 兑换码 -->
                    <form action="/">
                          <van-search 
                            v-model="convCode" 
                            show-action placeholder="请输入兑换码">
                                <template #action>
                                    <div @touchstart="onSearch">兑换</div>
                                </template>
                            </van-search>
                    </form>
                    <!-- 列表 -->
                    <div class="goods-list" v-if="noPay.length">
                        <div class="goods-item" v-for="item in noPay" :key="item.id">
                            <div class="item-top" @click="hrefUrl(item)">
                                <div class="item-img">                                
                                    <van-tag type="default" class="tag" v-if="item.order_code_status == 5"> {{item.order_code_status_text}}</van-tag>
                                    <van-image fit="fill" :src="item.get_goods.thumb_pic || 'https://khome2.tuzuu.com/klfront/newlogo.png'" width="100%" height="100%"/>
                                </div>
                                <div class="item-info">
                                    <div>
                                        <div class="item-info-title van-ellipsis">{{item.title}}</div>
                                        <div class="item-info-con van-multi-ellipsis--l2"> {{item.get_goods.check_items}}</div>
                                    </div>
                                    <div>
                                        <div class="info">
                                            <div class="item-info-money">
                                                <span style="font-size: 9px">¥</span><span style="font-size: 17px">{{ item.order_price.split(".")[0] }}</span>.<span>{{ item.order_price.split(".")[1] }}</span>
                                            </div>
                                            <div class="item-info-time">{{item.created_at}} </div>
                                        </div>
                                        <div class="item-bottom-button">
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <van-empty description="暂无数据" v-else/>
                </div>
            </van-tab>
			
        </van-tabs>
	 <ServicePopup v-model="isEwm" :kf_qrcode="ewm" />
    </div>
</template>

<script>
import cookie from "@/utils/manageCookie";
import { weChatPay } from "@/wechat/weChatPay";
import ServicePopup from "@/components/ServicePopup";
export default {
    data(){
        return{
            active:0,
            convCode:'',
            data:[],
            maxErrorCount:5,
            count:0,
			ewm:'s', //二维码
			isEwm:false,
        }
    },
	components:{
		ServicePopup
	},
    created(){
        let active = this.$route.query.active
        if(!active){
            this.active = 0
        }
        this.active = parseInt(active)
        this.getData()
    },
    computed:{
        // 待支付
        didPay(){
            if(this.data.length == 0){
                return this.data
            }
            else{
                return this.data.filter((value)=>{
                    return value.order_code_status == 2
                })
            }
        },
        // 已支付
        havePay(){
            if(this.data.length == 0){
                return this.data
            }
            else{
                return this.data.filter((value)=>{
					let status = value.order_code_status
                    if(status == 0 || status == 1 || status == 3 || status ==4){
                       if(value.comment_state == 0 || value.comment_state == 1){
							return value
                       }
                    }
                })
            }
        },
		//已完成
		okPay(){
			if(this.data.length == 0){
				return this.data
			}else{
				return this.data.filter(value => {
					let status = value.order_code_status
					if(status == 0 || status == 1 || status == 3 || status ==4){
					   if(value.comment_state == 2){
						   return value
					   }
					}
				})
			}
		},
        // 取消
        noPay(){
            if(this.data.length == 0){
                return this.data
            }
            else{
                return this.data.filter((value)=>{
                    return value.order_code_status == 5
                })
            }

        },
    },
    
    methods:{
		// 去评价
		goMsg(item){
			console.log(item)
			let data = {
				title:item.title,
				thumb_pic:item.get_goods.thumb_pic,
				desc:item.get_goods.check_items,
				price:item.order_price,
				num:item.goods_number,
				sn:item.sn
			}
			sessionStorage.setItem("commentsData",JSON.stringify(data))
			this.$router.push({path:'/comments'})
		},
        getData(){
            axios.get('/order_list').then(res=>{
                console.log(res)
                if(res.code == 1){
                    let sortData =  res.data.sort((a,b) => {
                        return a.created_at < b.created_at ? 1 : -1
                    })
                    this.data = sortData
                }
            })
        },
        async onSearch(){
              window._czc.push(['_trackEvent','我的订单', '点击兑换码'])
            if (this.count >= this.maxErrorCount) {
                this.$toast("尝试次数太多，请稍后再试。");
                return;
            } else {
                const code = this.convCode;
                if (!code) {
                    this.$toast("请输入兑换码");
                    return;
                }
                
                const res = await axios.get("/code_exchange", {
                    params: {
                        rep_code: code
                    }
                })
                console.log(res);
                if (res.code == 1) {
                    this.$toast("兑换成功");
                    window.location.reload();
                
                } else {
                    this.$toast(res.message);
                    this.manageCount();
                }
            }
        },
        manageCount() {
            this.count++; 
            const errorTime = cookie.get("exchangeErrorTime");
            if (errorTime) {
                cookie.set("exchangeCount", this.count, errorTime, "expires");
            } else {
                const date = new Date();
                const nowMin = date.getMinutes()
                date.setMinutes(nowMin + 10)
                date.toGMTString()
                const time = date.toGMTString();
                cookie.set("exchangeCount", this.count, time, "expires");
                cookie.set("exchangeErrorTime", time, time, "expires");
            }
        },
		//点击订单跳转到详情的时候，吧参数喘过气。
        hrefUrl(item){
			let {sn,order_code_status,get_goods} = item
			 this.$router.push({
				 path:`/my/detail`,
				 query:{
					 order_sn:sn,
				 },
			})
           
        },
		//弹出二维码
		openEwm(item){
			let {sn,order_code_status,get_goods} = item
			console.log(get_goods)
			if(get_goods.type == 2 || get_goods.type == 3 ){
				this.isEwm = true
				this.ewm = get_goods.kf_info.kf_qrcode || ''
			}else{
				this.$router.push({
					 path:`/my/detail`,
					 query:{
						 order_sn:sn,
					 },
				})
			}
		},
        createOrder(sn){
            axios.post(
                "continue_pay",
                {order_sn:sn}
            ).then(res => {
                if(res.code == 1){
                    this.wxPay(res.data.payConfig)
                }
            })
        },
        wxPay(config) {
            weChatPay({
                wxPayConfig: config,
                onSuccess: () => {
                    this.wxPaySuccess();
                },
            });
        },
        wxPaySuccess() {
            const toast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: "请稍候...",
            });
            setTimeout(() => {
                toast.type = "success";
                toast.message = "支付完成";
                setTimeout(() => {
                    this.getData()
                    toast.clear();
                }, 1500);
            }, 700);
        },
    },
    beforeRouteEnter(to, from, next){
        let count = cookie.get("exchangeCount");
        if (count) {
            count = parseInt(count.replace(/\D/g, "").slice(0, 1));
            if (!count) {
                count = 0;
            }
            next(vm => {	
                vm.count = count;
            })
        } else {
            next();
        }
    },
}
</script>

<style lang="less" scoped>
/deep/.van-tab__pane, /deep/.van-tab__pane-wrapper {
    height: 100%;
    overflow-y: auto;
}
.van-tabs {
    height: 100%;
}
/deep/.van-tabs__content {
    height: calc(100% - 44px);
}
    /deep/ .van-tab--active {
        font-weight: bold;
    }
   /deep/ .van-tab{
       font-size: 15px;
   }
   /deep/ .van-tabs__line {
        bottom: 20px;
    }
    .my-order {
        height: 100%;
    }
    .my-con{
        padding: 10px 15px;
        box-sizing: border-box;
        /deep/ .van-search{
            padding: 10px 0px;
        }
    }
    .goods-item{
        box-shadow: 0px 2px 7px 2px rgba(246, 246, 246, 0.73);
        padding: 10px;
        margin-bottom: 20px;
        font-size: 14px;
        border-radius: 5px;
        padding-bottom: 8px;
        position: relative;
        .item-top{
            width: 100%;
            display: flex;
            .item-img{
                width: 113px;
                height: 115px;
                margin-right: 10px;
                border-radius: 5px;
                overflow: hidden;
                position: relative;
                .tag {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    z-index: 3;
                    font-size: 11px;
                }
                /deep/ img {
                    box-shadow: 0px 2px 7px 2px rgba(246, 246, 246, 0.73);
                }
            }
            .item-info{
                width: calc(100% - 123px);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .item-info-title{
                    font-size: 14px;
                    color: #232323;
                    font-weight: bold;
                }
                .item-info-con{
                    color: #666;
                    font-size: 12px;
                }
                .info{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 3px;
                }
                .item-info-money{
                    font-size: 10px;
                    color: #D13939;
                    margin-right: 7px;
                    font-weight: bold;
                }
                .item-info-time{
                    font-size: 10px;
                    color:#999 ;
                }
            }
            .item-bottom-button {
                float: right;
            }
            /deep/ .van-button--small{
                height: 25px;
                width: 75px;
				margin-left: 10px;
            }
        }

    }
</style>
